(function ($) {
    "use strict";

    // $(window).on("load", function () {
    //     $("#preloader").fadeOut("slow", function () {
    //         $(this).remove();
    //     });
    // });

    $(window).on("scroll", function () {
        if ($(this).scrollTop() > 200) {
            $(".scrollup").fadeIn();
        } else {
            $(".scrollup").fadeOut();
        }
    });

    $(".scrollup").on("click", function () {
        $("html, body").animate(
            {
                scrollTop: 0,
            },
            800
        );
        return false;
    });

    /* ------------------ OWL CAROUSEL ------------------ */
    var $carouselDirection = $("html").attr("dir");
    if ($carouselDirection == "rtl") {
        var $carouselrtl = true;
    } else {
        var $carouselrtl = false;
    }

    $(".carousel").each(function () {
        var $Carousel = $(this);
        $Carousel.owlCarousel({
            loop: $Carousel.data("loop"),
            autoplay: $Carousel.data("autoplay"),
            margin: $Carousel.data("space"),
            nav: $Carousel.data("nav"),
            dots: $Carousel.data("dots"),
            center: $Carousel.data("center"),
            dotsSpeed: $Carousel.data("speed"),
            animateOut: "fadeOut",
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: $Carousel.data("slide-rs"),
                },
                1000: {
                    items: $Carousel.data("slide"),
                },
            },
        });
    });

    $("[data-background]").each(function () {
        $(this).css("background-image", "url(" + $(this).attr("data-background") + ")");
    });

    if ($(".search-popup-toggler").length) {
        $(".search-popup-toggler").on("click", function (e) {
            $(".search-popup").addClass("active");
            e.preventDefault();
        });
    }

    if ($(".search-popup-overlay").length) {
        $(".search-popup-overlay").on("click", function (e) {
            $(".search-popup").removeClass("active");
            e.preventDefault();
        });
    }

    $("#main-slider-3").owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        dots: false,
        autoplay: true,
        navSpeed: 1000,
        smartSpeed: 2000,
        navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
    });
    $(window).on("load", function () {
        $(".top-category-slider").owlCarousel({
            margin: 30,
            responsiveClass: true,
            nav: true,
            dots: false,
            navText: ["<i class='fas fa-chevron-left'></i>", "<i class='fas fa-chevron-right'></i>"],
            loop: true,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                700: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
                1300: {
                    items: 4,
                },
                1900: {
                    items: 4,
                },
            },
        });
    });

    $(window).on("load", function () {
        $(".testimonial-slider-3").owlCarousel({
            margin: 30,
            responsiveClass: true,
            nav: false,
            dots: true,
            loop: true,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1000: {
                    items: 2,
                },
                1300: {
                    items: 3,
                },
                1900: {
                    items: 3,
                },
            },
        });
    });

    //three-item-carousel
    if ($(".testimonial-featured-slide").length) {
        $(".testimonial-featured-slide").owlCarousel({
            loop: true,
            margin: 30,
            nav: true,
            smartSpeed: 1000,
            autoplay: 500,
            navText: ['<span class="fa fa-arrow-left"></span>', '<span class="fa fa-arrow-right"></span>'],
            responsive: {
                0: {
                    items: 1,
                },
                480: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                800: {
                    items: 2,
                },
                1024: {
                    items: 2,
                },
            },
        });
    }
    if ($(".testimonial-featured-slide-2").length) {
        $(".testimonial-featured-slide-2").owlCarousel({
            loop: true,
            margin: 30,
            nav: false,
            smartSpeed: 1000,
            autoplay: 500,
            responsive: {
                0: {
                    items: 1,
                },
                480: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                800: {
                    items: 2,
                },
                1024: {
                    items: 2,
                },
            },
        });
    }

    if ($(".owl-carousel-partners").length) {
        $(".owl-carousel-partners").owlCarousel({
            loop: true,
            lazyLoad: true,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            nav: false,
            responsive: {
                0: {
                    items: 3,
                    slideBy: 3,
                },
                992: {
                    items: 6,
                    slideBy: 6,
                },
            },
        });
    }

    if ($(".newsCarousel").length) {
        $(".newsCarousel").owlCarousel({
            loop: true,
            nav: false,
            dots: false,
            autoplay: true,
            autoplayTimeout: 4000,
            autoplayHoverPause: false,
            items: 6,
            margin: 20,
            responsive: {
                0: {
                    items: 2,
                },
                576: {
                    items: 2,
                },
                768: {
                    items: 4,
                },
                992: {
                    items: 6,
                },
            },
        });
    }

    if ($(".details-area-slide-carousel").length) {
        $(".details-area-slide-carousel").each(function () {
            var Self = $(this);
            var carouselOptions = Self.data("options");
            var carouselPrevSelector = Self.data("carousel-prev-btn");
            var carouselNextSelector = Self.data("carousel-next-btn");
            var thmCarousel = Self.owlCarousel(carouselOptions);
            if (carouselPrevSelector !== undefined) {
                $(carouselPrevSelector).on("click", function () {
                    thmCarousel.trigger("prev.owl.carousel");
                    return false;
                });
            }
            if (carouselNextSelector !== undefined) {
                $(carouselNextSelector).on("click", function () {
                    thmCarousel.trigger("next.owl.carousel");
                    return false;
                });
            }
        });
    }

    $(window).on("scroll", function () {
        if ($(window).scrollTop() > 100) {
            $(".header-main").addClass("sticky-menu");
        } else {
            $(".header-main").removeClass("sticky-menu");
        }
    });

    $(".nav-mobile-menu").on("click", function () {
        $(".mobile-menu-navbar").toggleClass("mobile-menu-on");
    });
    $(".nav-mobile-menu").on("click", function () {
        $("body").toggleClass("mobile-menu-overlay-on");
    });
    if ($(".mobile-menu li.dropdown ul").length) {
        $(".mobile-menu li.dropdown").append('<div class="dropdown-btn"><span class="fa fa-angle-down"></span></div>');
        $(".mobile-menu li.dropdown .dropdown-btn").on("click", function () {
            $(this).prev("ul").slideToggle(500);
        });
    }

    $("#main-slider").owlCarousel({
        items: 1,
        loop: true,
        nav: false,
        dots: true,
        autoplay: true,
        navSpeed: 1000,
        smartSpeed: 2000,
        animateOut: "fadeOut",
        animateIn: "fadeIn",
    });
    $("#main-slider-2").owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        dots: false,
        autoplay: true,
        navSpeed: 1000,
        smartSpeed: 2000,
        navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
    });
    $("#activity-slider-2").owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        dots: false,
        autoplay: true,
        navSpeed: 1000,
        smartSpeed: 2000,
        navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
    });

    $(window).on("load", function () {
        $("#activity-type-slider").owlCarousel({
            margin: 30,
            responsiveClass: true,
            nav: true,
            dots: false,
            navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
            loop: true,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
                1300: {
                    items: 3,
                },
                1900: {
                    items: 3,
                },
            },
        });
    });

    $(window).on("load", function () {
        $("#activity-featured-slider").owlCarousel({
            margin: 30,
            responsiveClass: true,
            nav: true,
            dots: false,
            navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
            loop: true,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
                1300: {
                    items: 3,
                },
                1900: {
                    items: 3,
                },
            },
        });
    });

    $(window).on("load", function () {
        $("#activity-featured-slider-2").owlCarousel({
            margin: 30,
            responsiveClass: true,
            nav: true,
            dots: false,
            navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
            loop: true,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
                1300: {
                    items: 4,
                },
                1900: {
                    items: 4,
                },
            },
        });
    });

    $(window).on("load", function () {
        $("#activity-featured-slider-3").owlCarousel({
            margin: 30,
            responsiveClass: true,
            nav: true,
            dots: false,
            navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
            loop: true,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1000: {
                    items: 2,
                },
                1300: {
                    items: 2,
                },
                1900: {
                    items: 2,
                },
            },
        });
    });

    var e = $(".image-grid");
    if (e.length)
        var o = e.imagesLoaded(function () {
            o.masonry({ percentPosition: !0, itemSelector: ".image-grid-item" });
        });

    $(window).on("load", function () {
        $("#testimonial-slide").owlCarousel({
            margin: 0,
            items: 1,
            nav: false,
            dots: true,
            loop: true,
        });
    });

    $(window).on("load", function () {
        // Testimonials Slider
        $(".testimonials-slider").owlCarousel({
            loop: true,
            margin: 30,
            singleItem: true,
            nav: false,
            dots: true,
            smartSpeed: 1000,
            autoplay: false,
            autoplayTimeout: 4000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1,
                },
                768: {
                    items: 2,
                },
                992: {
                    items: 2,
                },
            },
        });
    });

    var $grid = $(".grid").isotope({
        itemSelector: ".grid-item",
        layoutMode: "fitRows",
    });
    var filterFns = {
        numberGreaterThan50: function () {
            var number = $(this).find(".number").text();
            return parseInt(number, 10) > 50;
        },
        ium: function () {
            var name = $(this).find(".name").text();
            return name.match(/ium$/);
        },
    };
    $(".button-group").on("click", "button", function () {
        var filterValue = $(this).attr("data-filter");
        filterValue = filterFns[filterValue] || filterValue;
        $grid.isotope({ filter: filterValue });
    });

    $(".button-group").each(function (i, buttonGroup) {
        var $buttonGroup = $(buttonGroup);
        $buttonGroup.on("click", "button", function () {
            $buttonGroup.find(".is-checked").removeClass("is-checked");
            $(this).addClass("is-checked");
        });
    });

    $(window).on("load", function () {
        $("#blog-slider").owlCarousel({
            margin: 30,
            responsiveClass: true,
            nav: true,
            dots: false,
            navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
            loop: true,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
                1300: {
                    items: 3,
                },
                1900: {
                    items: 3,
                },
            },
        });
    });

    $(window).on("load", function () {
        $("#category-slide").owlCarousel({
            margin: 30,
            responsiveClass: true,
            nav: false,
            dots: true,
            loop: true,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
                1300: {
                    items: 3,
                },
                1900: {
                    items: 3,
                },
            },
        });
    });

    $(window).on("load", function () {
        $("#testimonial-slide-2").owlCarousel({
            margin: 30,
            responsiveClass: true,
            nav: false,
            dots: true,
            loop: true,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
                1300: {
                    items: 3,
                },
                1900: {
                    items: 3,
                },
            },
        });
    });

    $(window).on("load", function () {
        $("#blog-slider-2").owlCarousel({
            margin: 30,
            responsiveClass: true,
            nav: false,
            dots: true,
            loop: true,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
                1300: {
                    items: 3,
                },
                1900: {
                    items: 3,
                },
            },
        });
    });

    function AddReadMore() {
        //This limit you can set after how much characters you want to show Read More.
        var carLmt = 280;
        // Text to show when text is collapsed
        var readMoreTxt = " ... Read More";
        // Text to show when text is expanded
        var readLessTxt = " Read Less";

        //Traverse all selectors with this class and manupulate HTML part to show Read More
        $(".addReadMore").each(function () {
            if ($(this).find(".firstSec").length) return;

            var allstr = $(this).text();
            if (allstr.length > carLmt) {
                var firstSet = allstr.substring(0, carLmt);
                var secdHalf = allstr.substring(carLmt, allstr.length);
                var strtoadd = firstSet + "<span class='SecSec'>" + secdHalf + "</span><span class='readMore'  title='Click to Show More'>" + readMoreTxt + "</span><span class='readLess' title='Click to Show Less'>" + readLessTxt + "</span>";
                $(this).html(strtoadd);
            }
        });
        //Read More and Read Less Click Event binding
        $(document).on("click", ".readMore,.readLess", function () {
            $(this).closest(".addReadMore").toggleClass("showlesscontent showmorecontent");
        });
    }
    $(function () {
        //Calling function after Page Load
        AddReadMore();
    });

    $(document).on("click", "#notificationCard .btn-cancel", function () {
        $("#notificationCard").removeClass("active");
    });

    $(function () {
        setInterval(function () {
            $.ajax({
                url: "/ajax/live-sales",
                method: "get",
                dataType: "JSON",
                success: function (data) {
                    $("#notificationCard .text").html(data.message);
                    $("#notificationCard .time").html(data.time);
                    setTimeout(function () {
                        $("#notificationCard").addClass("active");
                    }, 1000);
                    setTimeout(function () {
                        $("#notificationCard").removeClass("active");
                    }, 10000);
                },
            });
        }, 30000);
    });
})(jQuery);
